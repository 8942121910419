import "../../../../../../frappe/frappe/public/js/frappe/form/layout.js";

frappe.ui.form.Layout = class Layout extends frappe.ui.form.Layout {
  render(new_fields) {
    super.render(new_fields);
    // Allow for universal form script setup
    this.universal_frm_setup?.(this);
  }

  attach_doc_and_docfields(refresh) {
    let me = this;
    for (let i = 0, l = this.fields_list.length; i < l; i++) {
      let fieldobj = this.fields_list[i];
      if (me.doc) {
        fieldobj.doc = me.doc;
        fieldobj.doctype = me.doc.doctype;
        fieldobj.docname = me.doc.name;
        fieldobj.df = fieldobj.df.use_df_provided
          ? fieldobj.df
          : frappe.meta.get_docfield(
              me.doc.doctype,
              fieldobj.df.fieldname,
              me.doc.name,
            ) || fieldobj.df;
      }
      refresh && fieldobj.df && fieldobj.refresh && fieldobj.refresh();
    }
  }

  refresh_dependency() {
    /**
            Resolve "depends_on" and show / hide accordingly
            build dependants' dictionary
        */

    let has_dep = false;

    const originalFields = this.fields_list.concat(this.tabs);

    const fields =
      this.grid_row?.parent_df.is_simplified_table &&
      this.frm?.docname === this.doc?.name
        ? frappe.meta.get_docfields(this.frm.safe_doctype).map((df) => ({ df }))
        : originalFields;

    for (let fkey in fields) {
      let f = fields[fkey];
      if (
        f.df.depends_on ||
        f.df.mandatory_depends_on ||
        f.df.read_only_depends_on
      ) {
        has_dep = true;
        break;
      }
    }

    if (!has_dep) return;

    let curColumn = undefined,
      curSection = undefined,
      curTab = undefined;

    // show / hide based on values (but also refer to current column, section & tab)
    for (let i = 0; i < fields.length; i++) {
      let f = fields[i];

      if (f.df.fieldtype === "Column Break") {
        curColumn = f;
      } else if (f.df.fieldtype === "Section Break") {
        curColumn = undefined;
        curSection = f;
      } else if (f.df.fieldtype === "Tab Break") {
        curColumn = undefined;
        curSection = undefined;
        curTab = f;
      }

      f = originalFields.find((f2) => f2.df.fieldname === f.df.fieldname);
      if (!f) continue;

      f.guardian_has_value = true;

      const possibleDependsOn = [f, curColumn, curSection, curTab].filter(
        (f) => f?.df.depends_on,
      );

      if (!possibleDependsOn.length) continue;

      // evaluate guardian
      f.guardian_has_value = possibleDependsOn.every((field) =>
        this.evaluate_depends_on_value(field.df.depends_on),
      );

      // show / hide
      if (f.guardian_has_value) {
        if (f.df.hidden_due_to_dependency) {
          f.df.hidden_due_to_dependency = false;
          f.refresh();
        }
      } else {
        if (!f.df.hidden_due_to_dependency) {
          f.df.hidden_due_to_dependency = true;
          f.refresh();
        }
      }
    }

    for (let i = fields.length - 1; i >= 0; i--) {
      let f =
        originalFields.find(
          (f2) => f2.df.fieldname === fields[i].df.fieldname,
        ) ?? fields[i];

      if (f.df.mandatory_depends_on) {
        this.set_dependant_property(
          f.df.mandatory_depends_on,
          f.df.fieldname,
          "reqd",
        );
      }

      if (!f.df.is_simplified_table && f.df.read_only_depends_on) {
        this.set_dependant_property(
          f.df.read_only_depends_on,
          f.df.fieldname,
          "read_only",
        );
      }
    }

    this.refresh_section_count();
  }

  set_dependant_property(condition, fieldname, property) {
    let set_property = this.evaluate_depends_on_value(condition);
    let value = set_property ? 1 : 0;
    let form_obj;

    if (this.frm) {
      form_obj = this.frm;
    } else if (this.is_dialog || this.doctype === "Web Form") {
      form_obj = this;
    }
    if (form_obj) {
      if (
        !this.grid?.df.is_simplified_table &&
        this.doc &&
        this.doc.parent &&
        this.doc.parentfield
      ) {
        form_obj.setting_dependency = true;
        form_obj.set_df_property(
          this.doc.parentfield,
          property,
          value,
          this.doc.parent,
          fieldname,
          this.doc.name,
        );
        form_obj.setting_dependency = false;
        // refresh child fields
        this.fields_dict[fieldname] && this.fields_dict[fieldname].refresh();
      } else {
        form_obj.set_df_property(fieldname, property, value);
      }
    }
  }
};
