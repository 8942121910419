frappe.provide("frappe.model");

// Nexfront: Override to not allow user to create certain fieldtypes (frappe.model.all_fieldtypes used in form_builder AddFieldButton.vue)
const NOT_ALLOWED_FIELDTYPES = [
  "Autocomplete",
  "Barcode",
  "Button",
  "Code",
  "Geolocation",
  "Heading",
  "HTML",
  "HTML Editor",
  "Image",
  "JSON",
  "Long Text",
  "Markdown Editor",
  "Password",
  "Read Only",
  "Rating",
  "Signature",
  "Table",
  "Table MultiSelect",
  "Text",
];

// On login, frappe.model.all_fieldtypes is not set for some reason and generates error (this is a fail-safe mechanism)
if (frappe.model.all_fieldtypes) {
  $.extend(frappe.model, {
    // Nexfront: Override to not allow user to create certain fieldtypes (frappe.model.all_fieldtypes used in form_builder AddFieldButton.vue)
    all_fieldtypes: frappe.model.all_fieldtypes.filter(
      (df) => !NOT_ALLOWED_FIELDTYPES.includes(df),
    ),

    set_default_views_for_doctype(doctype, frm) {
      frappe.model.with_doctype(doctype, () => {
        let meta = frappe.get_meta(doctype);
        let default_views = ["List", "Report", "Dashboard", "Kanban"];

        if (nexfront.utils.pipeline.shouldPipelineViewBeVisible(doctype)) {
          default_views.push("Pipeline");
          default_views = default_views.filter((v) => "Kanban" !== v);
        }

        if (meta.is_calendar_and_gantt && frappe.views.calendar[doctype]) {
          let views = ["Calendar", "Gantt"];
          default_views.push(...views);
        }

        if (meta.is_tree) {
          default_views.push("Tree");
        }

        if (frm.doc.image_field) {
          default_views.push("Image");
        }

        if (doctype === "Communication" && frappe.boot.email_accounts.length) {
          default_views.push("Inbox");
        }

        if (
          (frm.doc.fields?.find((i) => i.fieldname === "latitude") &&
            frm.doc.fields?.find((i) => i.fieldname === "longitude")) ||
          frm.doc.fields?.find(
            (i) => i.fieldname === "location" && i.fieldtype == "Geolocation",
          )
        ) {
          default_views.push("Map");
        }

        frm.set_df_property("default_view", "options", default_views);
      });
    },

    get_from_localstorage: function (doctype) {
      if (localStorage["_doctype:" + doctype]) {
        const masterDoc = JSON.parse(localStorage["_doctype:" + doctype])?.[0];
        return [masterDoc];
      }
    },

    set_in_localstorage: function (doctype, docs) {
      const [masterDoc] = docs;
      try {
        localStorage["_doctype:" + doctype] = JSON.stringify([masterDoc]);
      } catch (e) {
        // if quota is exceeded, clear local storage and set item
        console.warn("localStorage quota exceeded, clearing doctype cache");
        frappe.model.clear_local_storage();
        localStorage["_doctype:" + doctype] = JSON.stringify([masterDoc]);
      }
    },

    clear_local_storage: function () {
      for (var key in localStorage) {
        if (key.startsWith("_doctype:")) {
          localStorage.removeItem(key);
        }
      }
    },

    with_doctype: function (doctype, callback, async, with_parent = 1) {
      if (locals.DocType[doctype]) {
        callback && callback();
        return Promise.resolve();
      } else {
        let cached_timestamp = null;
        // TODO: Fix frappe localStorage cache (only if really necessary, since it was broken all along)
        // let cached_doc = null;

        // let cached_docs = frappe.model.get_from_localstorage(doctype);

        // if (cached_docs) {
        //     cached_doc = cached_docs.filter((doc) => doc.name === doctype)[0];
        //     if (cached_doc) {
        //         cached_timestamp = cached_doc.modified;
        //     }
        // }

        return frappe.call({
          method: "frappe.desk.form.load.getdoctype",
          type: "GET",
          args: {
            doctype: doctype,
            with_parent,
            cached_timestamp: cached_timestamp,
          },
          async: async,
          callback: function (r) {
            if (r.exc) {
              frappe.msgprint(__("Unable to load: {0}", [__(doctype)]));
              throw "No doctype";
            }
            // TODO: Fix frappe localStorage cache (only if really necessary, since it was broken all along)
            // if (r.message == "use_cache") {
            //     frappe.model.sync(cached_doc);
            // } else {
            //    frappe.model.set_in_localstorage(doctype, r.docs);
            // }
            frappe.model.init_doctype(doctype);

            if (r.user_settings) {
              // remember filters and other settings from last view
              frappe.model.user_settings[doctype] = JSON.parse(r.user_settings);
              frappe.model.user_settings[doctype].updated_on =
                moment().toString();
            }
            callback && callback(r);
          },
        });
      }
    },

    verify_delete(doctype, docname, callback) {
      frappe
        .call({
          method: "nexfront.overrides.delete_doc.get_docs_linked_with_doc",
          freeze: true,
          freeze_message: null,
          args: { doctype, docname },
        })
        .then((r) => {
          const linkedDocsMap = r.message;
          if (linkedDocsMap && callback) callback(linkedDocsMap);
        });
    },

    delete_doc: function (doctype, docname, callback) {
      let title = docname;
      let rawTitle = docname;

      const title_field = frappe.get_meta(doctype).title_field;

      if (title_field) {
        const value = frappe.model.get_value(doctype, docname, title_field);
        if (value) {
          rawTitle = value;
          title = `${value} (${docname})`;
        }
      }

      frappe.model.verify_delete(doctype, docname, function (linkedDocsMap) {
        const baseMessage = "This will delete {0} permanently. ";
        const linksList = Object.entries(linkedDocsMap).map(
          ([linkFieldname, linkedDocs]) => [
            `<b>${linkFieldname}</b> "${rawTitle}" in:`,
            linkedDocs.map(
              ([linkedParentDocType, referenceDocname, referenceDocTitle]) =>
                `<b>${linkedParentDocType}</b> "${
                  referenceDocTitle || referenceDocname
                }"`,
            ),
          ],
        );
        const MAX_ASSOCIATIONS = 10;
        let linkAssociationsCount = 0;
        const slicedLinksToDocsList = linksList
          .map(([linkFieldDescription, linkedDocMessages]) => {
            if (linkAssociationsCount > MAX_ASSOCIATIONS) {
              linkAssociationsCount += linkedDocMessages.length;
              return undefined;
            }
            linkAssociationsCount += linkedDocMessages.length;
            return [linkFieldDescription, linkedDocMessages];
          })
          .filter((l) => l);
        const ulStart =
          '<ul style="padding-left: 0;margin-left: 1rem;display: list-item;">';
        const ulEnd = "</ul>";
        const baseLinksToDocsMessage = slicedLinksToDocsList
          .map(
            ([linkFieldDescription, linkedDocMessages]) =>
              `${linkFieldDescription} ${linkedDocMessages.join(", ")}`,
          )
          .join(`${ulEnd}${ulStart}`);
        const linksToDocsMessage = `${ulStart}${baseLinksToDocsMessage}${ulEnd}`;
        const deletionMessage = linkAssociationsCount
          ? ` By deleting, <b>you will also remove the following Link associations</b>: ${linksToDocsMessage}`
          : "";
        const endMessage = "Do you want to proceed?";
        frappe.confirm(
          __(`${baseMessage}${deletionMessage}${endMessage}`, [title.bold()]),
          () => {
            this.disable_list_update = true;
            return frappe.call({
              method: "frappe.client.delete",
              args: { doctype: doctype, name: docname },
              freeze: true,
              freeze_message: __("Deleting {0}...", [title]),
              callback: function (r, rt) {
                if (!r.exc) {
                  frappe.utils.play_sound("delete");
                  frappe.model.clear_doc(doctype, docname);
                  if (callback) callback(r, rt);
                }
              },
            });
          },
          undefined,
          __("Delete Document"),
        );
      });
    },
  });
}
